import { gql } from '@apollo/client';

import { ChargesByFinancialAccountPayout } from '../models/chargesByFinancialAccountPayout';

interface ChargesByFinancialAccountPayoutConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: ChargesByFinancialAccountPayout[];
}

export interface ChargesByFinancialAccountPayoutList {
  listChargesByFinancialAccountPayoutIdentifier: ChargesByFinancialAccountPayoutConnection;
}

export const chargesByFinancialAccountPayoutTypename =
  'ListChargesByFinancialAccountPayoutIdentifierConnection';

const MAX_PAGE_SIZE = 100;

export const FINANCIAL_ACCOUNT_PAYOUT_INFOS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const FINANCIAL_ACCOUNT_PAYOUT_INFOS_QUERY = gql`
  query listChargesByFinancialAccountPayoutIdentifier(
    $pageSize: Int
    $after: String
    $before: String
    $filters: ListChargesByFinancialAccountPayoutIdentifierFilterOptions
  ) {
    listChargesByFinancialAccountPayoutIdentifier(
      after: $after
      before: $before
      filters: $filters
      pageSize: $pageSize
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        chargeFine
        chargeInterest
        chargeTotalPaid
        chargePaymentDate
        chargeAppTotalFees
        consumerUnit {
          id
          consumerUnitPowerDistributionUnitIdentifier
        }
        cooperativeMember {
          id
          cooperativeMemberLegalName
        }
      }
    }
  }
`;
