import { useHistory } from 'react-router-dom';

import { MdCircle } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';

import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import {
  FinancialAccountPayouts,
  FinancialAccountPayoutStatus,
} from 'financialAccountPayouts/models/financialAccountPayouts';

import { convertToBRL, formatDateToLocaleDateString } from 'utils/form';
import { FinancialAccountType } from 'financialAccounts/models/financialAccounts';

type Props = {
  financialAccountPayouts: FinancialAccountPayouts;
};

export default function FinancialAccountPayoutsTableItem({
  financialAccountPayouts: {
    id,
    financialAccount,
    financialAccountPayoutTotal,
    financialAccountPayoutStatus,
    financialAccountPayoutScheduleDate,
    financialAccountPayoutEffectiveDate,
  },
}: Props) {
  const { push } = useHistory();

  const FINANCIAL_ACCOUNT_PAYOUTS_INFOS_ROUTE =
    '/dashboard/billing-cycle/financial-account-payouts/infos';

  const ACCOUNT_TYPE = {
    [FinancialAccountType.SAVINGS]: 'Conta poupança',
    [FinancialAccountType.CHECKING]: 'Conta corrente',
  };

  const STATUS = {
    [FinancialAccountPayoutStatus.PENDING]: 'Pendente',
    [FinancialAccountPayoutStatus.ACCOMPLISHED]: 'Realizado',
    [FinancialAccountPayoutStatus.FAILED]: (
      <div className="flex items-center justify-start gap-4">
        <MdCircle className="text-red-500" size={10} />
        <p className="font-semibold">Falha</p>
      </div>
    ),
  };

  return (
    <TableBodyRow dataTestId={`financialAccountPayouts-row-id-${id}`}>
      <TableBodyColumn>{id}</TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          arrowPosition="top"
          placement="bottom-start"
          offset={{ mainAxis: 5 }}
          label={
            <div className="w-full flex flex-col items-start justify-center">
              <p className="font-light text-sm">
                Conta financeira: <br />
                <span className="font-semibold leading-5">
                  {financialAccount.financialAccountLegalName}
                </span>
              </p>
              <p className="font-light text-sm ">
                Documento: <br />
                <span className="font-semibold leading-5">
                  {financialAccount.financialAccountDocumentId}
                </span>
              </p>
              <p className="font-light text-sm">
                Dados bancários: <br />
                <span className="font-semibold leading-5">
                  Agência: {financialAccount.financialAccountBankAg} <br />
                  Conta: {financialAccount.financialAccountBankCc} <br />
                  Tipo: {ACCOUNT_TYPE[financialAccount.financialAccountType]} <br />
                </span>
              </p>
            </div>
          }
        >
          <p className="underline w-full flex">
            {financialAccount.financialAccountLegalName}
          </p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(financialAccountPayoutScheduleDate)}
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(financialAccountPayoutEffectiveDate)}
      </TableBodyColumn>
      <TableBodyColumn>{convertToBRL(financialAccountPayoutTotal)}</TableBodyColumn>
      <TableBodyColumn>{STATUS[financialAccountPayoutStatus]}</TableBodyColumn>
      <TableBodyColumn className="font-normal flex items-center justify-center">
        <AiFillEye
          className="cursor-pointer text-gray-dark500 hover:text-gray-dark400"
          size={20}
          onClick={() => {
            push({
              pathname: FINANCIAL_ACCOUNT_PAYOUTS_INFOS_ROUTE,
              state: {
                financialAccountPayoutId: id,
              },
            });
          }}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
