import { gql } from '@apollo/client';

import { FinancialAccountPayouts } from 'financialAccountPayouts/models/financialAccountPayouts';

interface FinancialAccountPayoutsConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: FinancialAccountPayouts[];
}

export interface FinancialAccountPayoutsList {
  financialAccountPayouts: FinancialAccountPayoutsConnection;
}

export const financialAccountPayoutsTypename = 'FinancialAccountPayoutsConnection';

const MAX_PAGE_SIZE = 100;

export const FINANCIAL_ACCOUNT_PAYOUTS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const FINANCIAL_ACCOUNT_PAYOUTS_QUERY = gql`
  query financialAccountPayouts(
    $pageSize: Int
    $after: String
    $before: String
    $filters: FinancialAccountPayoutFilterOptions
  ) {
    financialAccountPayouts(
      after: $after
      before: $before
      filters: $filters
      pageSize: $pageSize
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        financialAccountPayoutTotal
        financialAccountPayoutStatus
        financialAccountPayoutAccountName
        financialAccountPayoutScheduleDate
        financialAccountPayoutEffectiveDate
        financialAccount {
          id
          financialAccountType
          financialAccountBankAg
          financialAccountBankCc
          financialAccountLegalName
          financialAccountEntityType
          financialAccountDocumentId
          financialAccountAddressState
          financialAccountAddressPostalCode
        }
      }
    }
  }
`;
