import { FinancialAccount } from 'financialAccounts/models/financialAccounts';

export type FinancialAccountPayoutId = number;

export enum FinancialAccountPayoutStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  ACCOMPLISHED = 'ACCOMPLISHED',
}

export type FinancialAccountPayoutsCounter = {
  financialAccountPayoutsTotalAmount: string;
  financialAccountPayoutsFailedStatusCount: number;
  financialAccountPayoutsAccomplishedStatusCount: number;
};

export enum FinancialAccountPayoutExportType {
  SUMMEDUP = 'SUMMEDUP',
  DETAILED = 'DETAILED',
}

export interface FinancialAccountPayouts {
  id: FinancialAccountPayoutId;
  financialAccount: FinancialAccount;
  financialAccountPayoutTotal: string;
  financialAccountPayoutScheduleDate: string;
  financialAccountPayoutEffectiveDate: string;
  financialAccountPayoutStatus: FinancialAccountPayoutStatus;
}
