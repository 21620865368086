import { gql } from '@apollo/client';

import { FinancialAccountPayoutsCounter } from 'financialAccountPayouts/models/financialAccountPayouts';

export interface FinancialAccountPayoutsCounterList {
  financialAccountPayoutsCounter: FinancialAccountPayoutsCounter;
}

export const financialAccountPayoutsTypename = 'FinancialAccountPayouts';

export const FINANCIAL_ACCOUNT_PAYOUTS_COUNTER_QUERY = gql`
  query financialAccountPayoutsCounter {
    financialAccountPayoutsCounter {
      financialAccountPayoutsTotalAmount
      financialAccountPayoutsFailedStatusCount
      financialAccountPayoutsAccomplishedStatusCount
    }
  }
`;
