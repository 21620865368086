import { useHistory } from 'react-router-dom';

import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import { convertToBRL, formatDateToLocaleDateString } from 'utils/form';

import { ChargesByFinancialAccountPayout } from 'financialAccountPayouts/chargesByFinancialAccountPayout/models/chargesByFinancialAccountPayout';

type Props = {
  customCharges: ChargesByFinancialAccountPayout;
};

const CHARGES_ROUTE = '/dashboard/billing-cycle/charges';

export default function ChargesByFinancialAccountPayoutTableItem({
  customCharges: {
    id,
    chargeFine = '0',
    chargeInterest = '0',
    chargeTotalPaid = '0',
    chargePaymentDate = '',
    cooperativeMember: { cooperativeMemberLegalName = '' },
    consumerUnit: { consumerUnitPowerDistributionUnitIdentifier = '' },
  },
}: Props) {
  const { push } = useHistory();

  return (
    <TableBodyRow dataTestId={`financial-account-payout-infos-row-id-${id}`}>
      <TableBodyColumn className="underline cursor-pointer">
        <p
          onClick={() => {
            push({
              pathname: CHARGES_ROUTE,
              state: {
                id,
              },
            });
          }}
        >
          {id}
        </p>
      </TableBodyColumn>
      <TableBodyColumn>
        {consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn>{cooperativeMemberLegalName}</TableBodyColumn>
      <TableBodyColumn>{convertToBRL(chargeTotalPaid)}</TableBodyColumn>
      <TableBodyColumn>{convertToBRL(chargeInterest)}</TableBodyColumn>
      <TableBodyColumn>{convertToBRL(chargeFine)}</TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(chargePaymentDate)}
      </TableBodyColumn>
    </TableBodyRow>
  );
}
